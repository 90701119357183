// IntersectionObserver polyfill for IE and Safari.
import 'intersection-observer';

// requestIdleCallback not supported in all modern browsers.
// https://developers.google.com/web/updates/2015/08/using-requestidlecallback
if (typeof window !== 'undefined') {
    window.requestIdleCallback =
        window.requestIdleCallback ||
        function (cb) {
            const start = Date.now();
            return setTimeout(() => {
                cb({
                    didTimeout: false,
                    timeRemaining() {
                        return Math.max(0, 50 - (Date.now() - start));
                    },
                });
            }, 1);
        };
}

if (typeof global !== 'undefined' && !global.document) {
    // This handles an annoying dependency on a nearly-unused scriptjs library through the gtag library, while doing SSR
    global.document = {
        getElementsByTagName: function () {
            return [{}];
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
}
