/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import {observable, runInAction} from 'mobx';

import {FooterData} from '@udemy/footer';
import {getClientAppContext} from '@udemy/nextjs-core';
import {HeaderData} from '@udemy/react-header';
import {CommonStore} from '@udemy/store-provider';

// <<Stencil::Block(userImports)>>

// <</Stencil::Block>>

export interface RootStoreData {
    footerData: FooterData;
    headerData: HeaderData;
    locale: string;
    // <<Stencil::Block(rootStoreData)>>

    // <</Stencil::Block>>
}

// <<Stencil::Block(userDefined)>>

// <</Stencil::Block>>

/**
 * RootStore
 * This is a store class that acts a convienent place to store global application data that can be accessed via `useStores([RootStore])`.
 */
export class RootStore extends CommonStore {
    static readonly STORE_ID = 'RootStore';

    @observable headerData: HeaderData;
    @observable footerData: FooterData;

    // <<Stencil::Block(classProperties)>>

    // <</Stencil::Block>>

    constructor(private readonly data: RootStoreData) {
        super();

        // Store initialization data
        this.headerData = data.headerData;
        this.footerData = data.footerData;

        // <<Stencil::Block(userConstructor)>>

        // <</Stencil::Block>>
    }

    // <<Stencil::Block(userMethods)>>
    initializeClient() {
        // `onUserContextReady()` has the same behavior as `onLazyInit()`, but will wait when user data is loaded and ready
        this.onUserContextReady(() => {
            this.handleUserContextReady();
        });
    }

    handleUserContextReady = async () => {
        const clientAppContext = await getClientAppContext();

        runInAction(() => {
            const {header, footer} = clientAppContext;
            this.headerData = {
                ...this.headerData,
                ...header,
                isLoggedIn: this.udData?.me?.is_authenticated || false,
                isClientDataLoaded: true,
            };
            this.footerData = {
                ...this.footerData,
                ...footer,
            };
        });
    };
    // <</Stencil::Block>>
}
