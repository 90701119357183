import {DOMAIN_CONFIG, Tracker, getVisitorUuid} from '@udemy/event-tracking';
import {EVENT_TRACKING_APP_KEY} from '@udemy/nextjs-core';
import {HeaderData} from '@udemy/react-header';
import {UDData} from '@udemy/ud-data';

/**
 * Initialize Event Tracking
 *
 * @remarks
 * Right now, this needs to be called after client data is loaded because it depends on the header data. Please
 * move this above to the client data loading process once we have a way to get the subscription data from UD Global
 */
export function initializeEventTracking(udData: UDData, headerData: HeaderData, locale: string) {
    const {isMobile} = udData.request;

    const organizationId = udData.Config.brand.has_organization
        ? udData.Config.brand.organization.id
        : null;

    /**
     * Ideally this should be coming from udData.me but right now there's no support for subscription data inside
     * udData.me.
     */
    const isConsumerSubsSubscriber = headerData?.user?.consumer_subscription_active ?? false;

    // Initialize Tracker
    if (udData.userAgnosticTrackingParams.page_key) {
        Tracker.initialize({
            appKey: EVENT_TRACKING_APP_KEY,
            appLanguage: locale,
            appVersion: process.env.VERSION,
            clientKey: 'nextjs',
            domainConfig: DOMAIN_CONFIG.USE_CURRENT,
            isMobile,
            isTrackingEnabled: true,
            organizationId,
            pageKey: udData.userAgnosticTrackingParams.page_key,
            printLogs: true,
            sourceServiceName: process.env.APP_NAME,
            userId: udData.me.is_authenticated ? udData.me.id : undefined,
            visitorUuid: getVisitorUuid(),
            isD2CSubscriber: isConsumerSubsSubscriber,
        });
    } else {
        // eslint-disable-next-line no-console
        console.error('`page_key` is missing');
    }
}
