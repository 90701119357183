import $script from 'scriptjs';

import {Tracker} from '@udemy/event-tracking';
import {loadGtag, PageConfig} from '@udemy/gtag';
import {ANALYTICS_PARAMS, getConfigData} from '@udemy/shared-utils';
import {UDData} from '@udemy/ud-data';

export function loadTag(pageConfig: PageConfig, cb?: () => void) {
    // Load new GTM server-side capabilities via GTAG SDK
    const thirdPartyJSDelay = 3000;
    setTimeout(() => {
        window.requestIdleCallback(
            () => {
                const udConfig = getConfigData();
                const forceLoadGtag = new URLSearchParams(window.location.search).has(
                    'forceLoadGtag',
                );
                if (
                    forceLoadGtag ||
                    (udConfig.brand.is_external_sources_enabled &&
                        udConfig.brand.is_third_party_marketing_enabled)
                ) {
                    loadGtag(pageConfig, cb);
                }
            },
            {timeout: 2000},
        );
    }, thirdPartyJSDelay);
}

export function loadGTag(udData: UDData, locale: string) {
    const pageKey = udData.userAgnosticTrackingParams.page_key;
    const pageConfig = {
        app_key: Tracker.context.appKey,
        env: udData.Config.env,
        isCLP: pageKey === 'course_landing_page',
        isJapanese: locale === 'ja_JP',
        isLoggedInHomePage: pageKey === 'discovery_logged_in_home',
        isMarketPlaceUS: udData.Config.marketplace_country?.id === 'US',
        page_key: udData.userAgnosticTrackingParams.page_key as string,
    };
    loadTag(pageConfig);
}

export const loadClientSideGoogleTagManager = (
    udData: UDData,
    scriptLoadedCallback?: () => void,
    scriptLoader: (url: string, callback: () => void) => void = $script,
) => {
    const udConfig = udData.Config;
    if (
        udConfig.brand.is_external_sources_enabled &&
        udConfig.brand.is_third_party_marketing_enabled &&
        udConfig.third_party.google_tag_manager_id
    ) {
        // initialize the dataLayer if it doesn't exist, add to the layer if it does
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.dataLayer.push({
            isMember: 'id' in udData.me && udData.me.id > 0,
            env: udConfig.env,
        });
        (async () => {
            window.dataLayer.push(await ANALYTICS_PARAMS.user.extract());
        })();
        window.dataLayer.push({'gtm.start': Date.now(), event: 'gtm.js'});

        const tagManagerId = udConfig.third_party.google_tag_manager_id;
        const gtmUrl = `//www.googletagmanager.com/gtm.js?id=${tagManagerId}`;
        requestIdleCallback(
            () => {
                scriptLoader(gtmUrl, () => {
                    if (typeof scriptLoadedCallback === 'function') {
                        scriptLoadedCallback();
                    }
                });
            },
            {timeout: 2000},
        );
    }
};
