const {
    i18n,
    i18nPathToBundleMap,
    i18nLocaleToLangMap,
    i18nPreferred,
    i18nBundleNames,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('@udemy/nextjs-core');

module.exports = {
    i18n,
    i18nPathToBundleMap,
    i18nLocaleToLangMap,
    i18nPreferred,
    i18nBundleNames,
};
