import {initPerformanceCollection} from '@udemy/performance-rum-client';
import {UDData} from '@udemy/ud-data';

/**
 * Initialize Performance Collection
 */
export function initializePerformanceTracking(udData: UDData) {
    const {isMobile, isPC: isDesktop} = udData.request;

    // Initialize Performance RUM collection
    initPerformanceCollection({
        deviceType: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        isFirstTimeVisitor: udData.visiting.is_first_time_visitor,
        isPageCached: false,
    });
}
