// IMPORTANT: Import global less first to avoid CSS cascading issues
import '../less/_app.global.less';

import 'src/lib/polyfills';
import 'src/lib-server/django-shims';
import {Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useStaticRendering} from 'mobx-react-lite';
import NextApp, {AppContext} from 'next/app';
import {useEffect, useState} from 'react';

import {CommonStoresProvider} from '@udemy/common-stores';
import {detectKeyboardNavigation} from '@udemy/design-system-utils';
import {I18nProvider, useI18n} from '@udemy/i18n';
import {
    APP_CONTEXT_VAR_NAME,
    getClientAppContext,
    mergeWithArrayOverwrite,
    RENDER_MODES,
} from '@udemy/nextjs-core';
import {shimUDPerformance} from '@udemy/performance-rum-client';
import {ExperimentationProvider} from '@udemy/react-experimentation-provider';
import {ShoppingClient} from '@udemy/shopping';
import {StoreProvider} from '@udemy/store-provider';
import {UDData, UDDataConfig, UDDataProvider, useUDData, useUDLink} from '@udemy/ud-data';

import {i18nPathToBundleMap} from 'next.config.i18n';
import {BindI18nGlobals} from 'src/components/bind-i18n-globals/bind-i18n-globals';
import {AppWithLayoutProps} from 'src/components/layout/types';
import {useDevice} from 'src/hooks/useDevice';
import {
    BROWSE_AGGREGATOR_PAGE_FEATURE,
    CAREER_ACADEMIES,
    COURSE_CARD_PREFETCH_FEATURE_CODE,
    COURSE_CARDS_OPEN_IN_NEW_TAB,
    COURSE_GUIDANCE_FEATURE,
    DELAY_COURSE_CARD_QUICK_VIEW_BOX,
    DESKTOP_POST_ADD_TO_CART_BEHAVIOR,
    HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX,
    LEARNERS_ARE_VIEWING_ON_LOHP,
    NEW_SEARCH_BAR_MX,
    POPULAR_CATEGORIES_MODULE,
    SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER,
    SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE,
} from 'src/lib/experimentation-helper';
import {configureUdApi} from 'src/lib/ud-global-fetch';
import {IntegrationsStore} from 'src/stores/integrations-store';
import {RootStore} from 'src/stores/root-store';

// Configure udApi
configureUdApi();

// TODO: rehyrdate from pageProps instead
const queryClient = new QueryClient();

export const EXPERIMENTATION_FEATURES = [
    BROWSE_AGGREGATOR_PAGE_FEATURE,
    CAREER_ACADEMIES,
    COURSE_CARD_PREFETCH_FEATURE_CODE,
    COURSE_CARDS_OPEN_IN_NEW_TAB,
    COURSE_GUIDANCE_FEATURE,
    DELAY_COURSE_CARD_QUICK_VIEW_BOX,
    DESKTOP_POST_ADD_TO_CART_BEHAVIOR,
    HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX,
    LEARNERS_ARE_VIEWING_ON_LOHP,
    POPULAR_CATEGORIES_MODULE,
    SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER,
    SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE,
    NEW_SEARCH_BAR_MX,
];

/**
 * Prevent observable components to re-render on the server. Other wise this causes a memory leak since lots of mobx
 * references being created on the server and never disposed.
 * @see https://github.com/mobxjs/mobx/tree/main/packages/mobx-react-lite#enablestaticrenderingenable-true
 */
// eslint-disable-next-line react-hooks/rules-of-hooks
useStaticRendering(typeof window === 'undefined');

const I18nAccessBootstrapper = () => {
    const i18n = useI18n();
    const udData = useUDData();
    const udLink = useUDLink();

    useEffect(() => {
        if (!udData.isGlobalMeContextLoading) {
            ShoppingClient.initialize({
                i18n,
                udLink,
                isShoppingCartFeatureEnabled: udData.Config.features.shopping_cart,
            });

            if (udData.me.is_authenticated && udData.me.id) {
                ShoppingClient.setUserId(udData.me.id);
            }

            ShoppingClient.fetch();
        }
    }, [i18n, udData, udLink]);

    return null;
};

export default function App({
    Component,
    locale, // NOTE: this is just the language portion of the locale, i.e. en instead of en_US
    pageProps,
}: AppWithLayoutProps) {
    // Get application context and render mode
    const {appContext, renderMode} = pageProps;

    // resolve full locale
    const fullLocale = i18nPathToBundleMap[locale as keyof typeof i18nPathToBundleMap];

    // Initialize root store
    const [rootStore] = useState(
        () =>
            new RootStore({
                footerData: appContext.footer,
                headerData: appContext.header,
                locale: fullLocale,
            }),
    );
    const [integrationsStore] = useState(() => new IntegrationsStore());

    // Shim device types
    const {isMobile, isDesktop, isTablet} = useDevice();

    // local UD data state
    const [udData, setUDData] = useState(() => {
        const initUDData = mergeWithArrayOverwrite(appContext.udData, {
            Config: {
                app_name: process.env.APP_NAME,
                features: {
                    notice: {
                        smart_bar: true,
                    },
                },
            } as unknown as UDDataConfig,
            userAgnosticTrackingParams: {
                page_key: pageProps.pageKey,
            },
            isGlobalMeContextLoading: true,
            request: {
                isMobile: isMobile,
                isPC: isDesktop,
                isTablet: isTablet,
                language: locale,
                locale: fullLocale,
            },
        });
        return initUDData;
    });

    // Shim UD.performance global API
    shimUDPerformance();

    useEffect(() => {
        async function bootstrapClient() {
            // Get client UD data.  If we are in SSR mode, the data is already available in scope
            const {udData: clientUDData} =
                renderMode === RENDER_MODES.SSR ? await {udData} : await getClientAppContext();

            const fullUdData: UDData = mergeWithArrayOverwrite(udData, clientUDData);

            // Ensure we've set user context has loaded flag
            const fullUdDataWithOverrides: UDData = mergeWithArrayOverwrite(fullUdData, {
                isGlobalMeContextLoading: false,
                /* needed to enable smart-bar */
                Config: {
                    features: {
                        notice: {
                            smart_bar: true,
                        },
                    },
                },
            });

            // Update udData
            setUDData(fullUdDataWithOverrides);

            // If you are SSRing the page, you need to write global UD data to the window object.
            // This will be eventually replaced by a standard app context provider.
            if (renderMode === RENDER_MODES.SSR) {
                const appContextData = {
                    footer: appContext.footer,
                    header: appContext.header,
                    udData: fullUdDataWithOverrides,
                };
                Object.assign(window, {
                    [APP_CONTEXT_VAR_NAME]: appContextData,
                });
            }

            // Initialize application stores
            rootStore.initializeClient();
            integrationsStore.initializeClient();

            // Turn on keyboard navigation detection
            detectKeyboardNavigation();

            return fullUdDataWithOverrides;
        }

        bootstrapClient();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    // Use the layout defined at the page level, if available
    // https://nextjs.org/docs/basic-features/layouts#per-page-layouts
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <>
            <I18nProvider lang={locale} locale={fullLocale} translations={pageProps.translations}>
                <UDDataProvider data={udData as UDData} writeToGlobal={true}>
                    <CommonStoresProvider>
                        <StoreProvider stores={[rootStore, integrationsStore]}>
                            <QueryClientProvider client={queryClient}>
                                <Hydrate state={pageProps.dehydratedState}>
                                    <ExperimentationProvider features={EXPERIMENTATION_FEATURES}>
                                        <BindI18nGlobals />
                                        <I18nAccessBootstrapper />
                                        {getLayout(<Component {...pageProps} />)}
                                    </ExperimentationProvider>
                                </Hydrate>
                            </QueryClientProvider>
                        </StoreProvider>
                    </CommonStoresProvider>
                </UDDataProvider>
            </I18nProvider>
        </>
    );
}

// Do not resolve anything user specific in this function.
App.getInitialProps = async function (appContext: AppContext) {
    /**
     * Required step from https://nextjs.org/docs/advanced-features/custom-app#caveats
     *
     * When you add getInitialProps in your custom app, you must import App from "next/app",
     * call App.getInitialProps(appContext) inside getInitialProps and merge the returned object into the return value.
     */
    const locale = appContext.router.locale as string;
    const [nextAppContext] = await Promise.all([NextApp.getInitialProps(appContext)]);
    return {
        ...nextAppContext,
        locale,
    };
};
