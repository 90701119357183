const DEPLOY_ENV = process.env.DEPLOY_ENV || 'local';

const monolithPublicEndpoints = {
    local: 'https://www.udemy.dev',
    dev: 'https://www.udemy.dev',
    prod: 'https://www.udemy.com',
};

const monolithInternalUrls = {
    local: 'https://www.udemy.dev',
    dev: 'http://django-monolith.udemy.svc.cluster.local',
    prod: 'http://django-monolith.udemy.svc.cluster.local',
};

const graphQLPublicEndpoints = {
    local: 'https://www.udemy.dev/api/2024-01/graphql/',
    dev: 'https://www.udemy.dev/api/2024-01/graphql/',
    prod: 'https://www.udemy.com/api/2024-01/graphql/',
};

const graphQLInternalEndpoints = {
    local: 'https://www.udemy.dev/api/2024-01/graphql/',
    dev: 'http://api-platform-graphql-2024-01.api-platform.svc.cluster.local/graphql/',
    prod: 'http://api-platform-graphql-2024-01.api-platform.svc.cluster.local/graphql/',
};

// <<Stencil::Block(customEndpointDefs)>>

// <</Stencil::Block>>

module.exports = {
    internalConfig: {
        GRAPHQL_URL: process.env.INTERNAL_GRAPHQL_URL ?? graphQLInternalEndpoints[DEPLOY_ENV],
        MONOLITH_URL: process.env.INTERNAL_MONOLITH_URL ?? monolithInternalUrls[DEPLOY_ENV],
        // <<Stencil::Block(customInternalEndpointExports)>>

        // <</Stencil::Block>>
    },
    publicConfig: {
        GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL ?? graphQLPublicEndpoints[DEPLOY_ENV],
        MONOLITH_URL: process.env.NEXT_PUBLIC_MONOLITH_URL ?? monolithPublicEndpoints[DEPLOY_ENV],
        // <<Stencil::Block(customPublicEndpointExports)>>

        // <</Stencil::Block>>
    },
};
