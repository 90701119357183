/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import {observable, runInAction} from 'mobx';

import {bootstrapBraze} from '@udemy/braze';
import {FooterData} from '@udemy/footer';
import {getClientAppContext} from '@udemy/nextjs-core';
import {initializeOneTrust} from '@udemy/onetrust';
import {HeaderData} from '@udemy/react-header';
import {CommonStore} from '@udemy/store-provider';
import {UDData} from '@udemy/ud-data';

import {initializeEventTracking} from 'src/lib/event-tracking';
import {initializePerformanceTracking} from 'src/lib/performance';
import {initializeSentryClient} from 'src/lib/sentry';
import {loadGTag, loadClientSideGoogleTagManager} from 'src/lib/gtm';
import {provideMarketingAttribution} from 'src/lib/marketing-attribution';

// <<Stencil::Block(userImports)>>

// <</Stencil::Block>>

export interface IntegrationsStoreData {
    footerData: FooterData;
    headerData: HeaderData;
    locale: string;
    // <<Stencil::Block(integrationsStoreData)>>

    // <</Stencil::Block>>
}

// <<Stencil::Block(userDefined)>>

// <</Stencil::Block>>

export class IntegrationsStore extends CommonStore {
    static readonly STORE_ID = 'IntegrationsStore';

    @observable headerData?: HeaderData;
    @observable footerData?: FooterData;

    // <<Stencil::Block(classProperties)>>

    // <</Stencil::Block>>

    initializeClient() {
        this.initIntegrations();
        this.onUserContextReady(this.initUserSpecificIntegrations);
        // <<Stencil::Block(initializeClient)>>

        // <</Stencil::Block>>
    }

    initIntegrations = async () => {
        const udData = this.udData as UDData;
        initializeOneTrust();
        initializePerformanceTracking(udData as UDData);
        // <<Stencil::Block(initIntegrations)>>

        // <</Stencil::Block>>
    };

    /**
     * Initialize various tracking and analytics integrations.
     */
    initUserSpecificIntegrations = async () => {
        const udData = this.udData as UDData;
        const locale = udData.request.locale;
        await this.loadClientData(udData);

        // Initialize integrations that depend on full user context
        initializeEventTracking(udData, this.headerData as HeaderData, locale);
        initializeSentryClient(udData, this.headerData as HeaderData);
        loadGTag(udData, locale);
        // Enable client-side Google Tag Manager
        loadClientSideGoogleTagManager(udData);
        provideMarketingAttribution();
        // TODO: `bootstrapBraze` should take udData as argument and stop reading global scope
        bootstrapBraze();
        // <<Stencil::Block(initUserSpecificIntegrations)>>

        // <</Stencil::Block>>
    };

    /**
     * Fetch client-side app context with user specific information and merge it with local data
     */
    private loadClientData = async (udData: UDData) => {
        const clientAppContext = await getClientAppContext();

        runInAction(() => {
            const {header, footer} = clientAppContext;

            this.headerData = {
                ...this.headerData,
                ...header,
                isLoggedIn: udData.me.is_authenticated,
                isClientDataLoaded: true,
            };
            this.footerData = {
                ...this.footerData,
                ...footer,
            };
        });
    };
}
